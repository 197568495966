import React from 'react';
import PartnersQuery from '../../query-components/PartnersQuery/PartnersQuery';
import ResponsiveList from '../../views/ResponsiveList/ResponsiveList';
import { GatsbyImage } from 'gatsby-plugin-image';
import { partners } from '../../query-components/PartnersQuery/partners-list';

const CategorizedPartnersList = () => {
  return (
    <PartnersQuery
      render={data => (
        <div>
          {PARTNER_CATEGORIES.map((category, i) => (
            <div
              key={i}
              className="border-b border-gray-300 py-10 last:border-none"
            >
              <ResponsiveList
                sliderProps={{ darkControls: true }}
                classNameGridItemSetting={`md:w-1/3 px-10 py-4`}
              >
                {category.map((partnerKey, i) => {
                  return (
                    <a
                      key={i}
                      href={data[partnerKey].url}
                      target="_blank"
                      rel="noreferrer"
                      aria-label={partnerKey}
                      className="px-4 block"
                    >
                      <GatsbyImage
                        image={data[partnerKey].image}
                        alt={partners.find(p => p.image === partnerKey).alt}
                      />
                    </a>
                  );
                })}
              </ResponsiveList>
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default CategorizedPartnersList;

/*
 * Partners from PartnersQuery are sectioned into categories by their object key.
 * View README.md on how to add a partner logo
 */
const CATEGORY_1_PARTNERS: string[] = [
  'partner1',
  'partner5',
  'partner16',
  'partner13',
  'partner11',
  'partner37',
  'partner12',
  'partner17',
  'partner14',
  'partner18',
];

const CATEGORY_2_PARTNERS: string[] = [
  'partner2',
  'partner7',
  'partner20',
  'partner4',
  'partner21',
  'partner3',
  'partner36',
  'partner39',
  'partner35',
  'partner38',
  'partner22',
  'partner40',
  'partner9',
  'partner23',
  'partner42',
  'partner44',
  'partner43',
  'partner45',
  'partner46',
  'partner47',
  'partner68',
  'partner69',
];

const CATEGORY_3_PARTNERS: string[] = [
  'partner10',
  'partner24',
  'partner8',
  'partner25',
  'partner26',
  'partner27',
  'partner28',
  'partner29',
  'partner30',
  'partner31',
  'partner41',
  'partner32',
  'partner48',
  'partner49',
  'partner50',
];

const CATEGORY_4_PARTNERS: string[] = ['partner33', 'partner15', 'partner34'];

const PARTNER_CATEGORIES: Array<string[]> = [
  CATEGORY_1_PARTNERS,
  CATEGORY_2_PARTNERS,
  CATEGORY_3_PARTNERS,
  CATEGORY_4_PARTNERS,
];
